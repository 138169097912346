$main-blue: #2fafff;

.container {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // align-content: center;
  // justify-content: center;
  // justify-items: center;
  width: 100%;
  height: 100%;
  // min-width: 600px;
  // min-height: 500px;
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  min-height: 60px;
  height: 4rem;
}

.navbar-center {
  font-family: 'Pacifico', cursive;
  font-size: 2.3rem;
  min-width: 250px;
  width: 60vw;
}

.navbar-logo {
  font-family: 'Pacifico', cursive;
  font-size: 2.3rem;
  user-select: none;
}

.board-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  min-width: 250px;
  min-height: 250px;
  margin: 0;
  padding: 0;
}

.blue-text {
  color: $main-blue;
}
