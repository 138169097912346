$main-dark: #676f77;
$main-blue: #2fafff;
$main-lighter-blue: #4ebbff;
$main-dark-text: #3b3d41;
$border-bold: 0.15rem solid $main-dark;
$border-light: 0.1rem solid $main-dark;
$border-radius: 0;

.board {
  display: grid;
  min-width: 250px;
  width: min(60vw, 60vh); 
  min-height: 250px;
  height: min(60vw, 60vh);
  grid-template-columns: repeat(9, 1fr);
  grid-auto-rows: 1fr;
  font-size: 1rem;
  user-select: none;
}

.tile {
  display: flex;
  align-content: center;
  align-items:  center;
  justify-content: center;
  justify-items:  center;
  cursor: pointer;
}

.tile.changeable {
  color: $main-blue;
}

.tile.selected {
  background-color: $main-lighter-blue;
  color: white;
}

.tile.related {
  background-color: #cce9fc;
}

.tile.box-related {
  background-color: #e7f5fd;
}
 
.s-2 {
  grid-template-columns: repeat(4, 1fr);
}

.b-l-l {
  border-left: $border-light;
}

.b-l-b {
  border-left: $border-bold;
}

.b-r-l {
  border-right: $border-light;
}

.b-r-b {
  border-right: $border-bold;
}

.b-t-l {
  border-top: $border-light;
}

.b-t-b {
  border-top: $border-bold;
}

.b-b-b {
  border-bottom: $border-bold;
}

@media only all and (min-width: 500px) and (min-height: 500px) {
  .board {
    font-size: 1.3rem;
  }
}

@media only all and (min-width: 800px) and (min-height: 600px) {
  .board {
    font-size: 1.5rem;
  }
}

@media only all and (min-width: 1100px) and (min-height: 700px) {
  .board {
    font-size: 2rem;
  }
}